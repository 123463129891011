import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from '~/routes';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';
import Footer from './components/Footer';
import Header from './components/Header';

const App: React.FC = () => {
  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <AppProvider>
          <Header />
          <Routes />
          <Footer />
        </AppProvider>
        <GlobalStyles />
      </BrowserRouter>
    </HttpsRedirect>
  );
};

export default App;
