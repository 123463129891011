import styled from 'styled-components';

export const Container = styled.div`
  .slick-prev {
    background: #00d97f;
  }
  .slick-next {
    background: #00d97f;
  }
  .img-car {
    border: 1px solid #d7cfcf;
    border-radius: 17px;
    position: relative;
    background: #f9f9f9;
    p {
      font-size: 20px;
    }
  }
  .img-car-knowMore {
    border: 1px solid #d7cfcf;
    border-radius: 17px;
    position: relative;
    background: #00ba6d;
    span {
      background: none;
      color: #ffffff;
    }
  }
  .soccer-shoe-esp {
    margin-top: -50px;
    z-index: 99;
    position: relative;
  }
`;

export const ButtonLeft = styled.button`
  height: 76px !important;
  width: 76px !important;
  background: #f9f9f9;
  border: 1px solid #d7cfcf;
  border-radius: 6px;
  position: absolute;
  bottom: -70px;
  left: 0px;
  transition-duration: 0.3s;
  :hover {
    background: #00d97f;
  }
`;
export const ButtonRight = styled.button`
  height: 76px !important;
  width: 76px !important;
  background: #f9f9f9;
  border: 1px solid #d7cfcf;
  border-radius: 6px;
  position: absolute;
  bottom: -70px;
  left: 96px;
  transition-duration: 0.3s;
  :hover {
    background: #00d97f;
  }
`;
