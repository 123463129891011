import React from 'react';

import { Container } from './styles';

interface ILoading {
  active: boolean;
}

const Loading: React.FC<ILoading> = ({ active }) => {
  return (
    <Container active={active}>
      <div className="loading-box">
        <div className="spinner-border text-light" role="status" />
        <span className="sr-only">Por favor, aguarde</span>
      </div>
    </Container>
  );
};

export default Loading;
