import React from 'react';
import { RiFacebookFill, RiInstagramLine } from 'react-icons/ri';
import { SiWhatsapp } from 'react-icons/si';

import { Container } from './styles';

import logo_footer from '~/assets/logos/logo-footer.svg';

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="footer-border">
        <div className="container">
          <div className="row mx-2">
            <div className="col-6 col-lg-4 my-5 pt-4">
              <img src={logo_footer} alt="logo" />
            </div>
          </div>
          <div className="row mx-2 pb-4 justify-content-between">
            <div className="col-8 mb-4 col-lg-2">
              <h5>Domine o jogo com a Chuteira de Campo</h5>
            </div>
            <div className="col-12 col-lg-3 d-flex align-items-lg-center justify-content-end order-1 order-lg-0">
              <div className="icon-footer d-flex justify-content-center align-items-center me-3">
                <RiFacebookFill />
              </div>
              <a
                href="https://wa.me/5516997849857"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-footer d-flex justify-content-center align-items-center me-3">
                  <SiWhatsapp />
                </div>
              </a>
              <a
                href="https://www.instagram.com/knarinbr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-footer d-flex justify-content-center align-items-center">
                  <RiInstagramLine />
                </div>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=5516996170181"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-whatsapp-call d-flex justify-content-center align-items-center">
                  <SiWhatsapp size={35} color="#FFFFFF" />
                </div>
              </a>
            </div>
            <div className="col-12 my-5 px-3 col-lg-10 line-footer w-100 mb-4 order-0 order-lg-1" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
