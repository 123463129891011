import styled from 'styled-components';
import soccer from '~/assets/images/field.png';
import mask_ball from '~/assets/images/mask-ball.png';
import kanarin from '~/assets/banners/knarin.png';

export const Container = styled.div`
  .card-box {
    zoom: 1.15;

    .ReactCreditCard__container {
      > .ReactCreditCard {
        box-shadow: 2px 2px 10px 1px #0000005e;
      }

      .ReactCreditCard__name {
        font-size: 14px;
      }
    }
  }

  .input {
    background: #f2f2f2;
    border: 1px solid #dadada;
    padding: 0px 25px 0px 25px;

    input {
      color: #626262;
    }
  }

  button {
    background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    border-radius: 10px;
    color: #202020;
  }

  .box {
    border: 1px solid #d7cfcf;
    border-radius: 17px;
    background: #f9f9f9;
    position: sticky;
    top: 125px;
  }
`;
