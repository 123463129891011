import styled from 'styled-components';
import soccer from '~/assets/images/field.png';
import mask_ball from '~/assets/images/mask-ball.png';
import kanarin from '~/assets/banners/knarin.png';

export const Container = styled.div`
  .main-text {
    font-size: 40px;
    .text-gradient {
      line-height: 39px;
      background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  .beyond {
    font-size: 20px;
  }
  .home-height {
    height: 750px;
  }
  .background-boots {
    background: linear-gradient(
      180deg,
      rgba(15, 223, 183, 0.6) -44.4%,
      rgba(170, 242, 60, 0.6) 130.97%
    );
    border-radius: 0px 0px 32px 32px;
  }
  .banner-boots {
    position: relative;
    bottom: -40px;
  }
  .rect {
    width: 50px;
    height: 9px;
    background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    border-radius: 100px;
  }
  .content-text {
    font-size: 30px;
    color: #202020;
  }
  .ball {
    width: 20px;
    height: 20px;
  }
  .sportTech {
    font-size: 24px;
    .sport-bold {
      color: #202020;
    }
  }
  .our-brand {
    background: #d1f5e2;
    border-radius: 32px;
    h2 {
      font-size: 24px;
    }
    p {
      color: #486861;
      font-size: 16px;
    }
  }
  .our-love {
    background: #bbe7d0;
    border-radius: 32px;
    h2 {
      font-size: 24px;
    }
    p {
      color: #486861;
      font-size: 16px;
    }
  }
  .signature {
    border-radius: 20px 20px 0px 0px;
    background-image: url(${soccer});
    background-repeat: no-repeat;
    background-size: cover;
    b {
      font-weight: 600;
    }
  }
  .sign-text {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
  }
  .sign-text-p {
    color: #e4e4e4;
    font-weight: 400;
    font-size: 16px;
    .text-p {
      width: calc(100% - 5px);
    }
  }
  .vertical-bar {
    background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    border-radius: 8px;
    width: 3px;
  }
  .plan {
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 3px 4px 23px rgba(95, 95, 95, 0.1),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(230, 230, 230, 0.5);
    border-radius: 18px;
  }
  .ball-ace {
    background-color: #aef7ac;
    border-radius: 18px;
    font-weight: 600;
    font-size: 16px;
    color: #2d2e2e;
  }
  .n-month {
    font-weight: 600;
    font-size: 50px;
    color: #000000;
  }
  .month {
    font-weight: 300;
    font-size: 24px;
    color: #707070;
  }
  .attribute {
    font-size: 14px;
    color: #707070;
  }
  .know-more {
    font-weight: 500;
    font-size: 18px;
    color: #202020;
    background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    border-radius: 18px;
    border: none;
  }
  .imgre {
    background: blueviolet;
    border-radius: 22px;
    width: 160px;
    height: 160px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .line-test {
    border: 1px dashed #ffffff;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -10;
  }
  .feature {
    background: #202020;
    h2 {
      color: #ffffff;
      font-size: 30px;
      b {
        font-weight: 600;
      }
    }
  }
  .feature-text {
    color: #ffffff;
    font-size: 18px;
  }
  .icons {
    width: 105px;
    height: 105px;
    background: #00d97f;
    border-radius: 22px;
  }
  .shoe {
    color: #000000;
    width: 100px;
    height: 100px;
  }
  .work {
    z-index: 1;
  }
  .how-work {
    color: #000000;
    font-size: 42px;
  }
  .line {
    border: 1px dashed #ffffff;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
  .line-hz {
    background: #00d97f;
    border-radius: 9px;
    width: 50px;
    height: 10px;
  }

  .mask {
    background-size: cover;
    background-color: #00d97f;
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: 20px 20px 0px 0px;
    h2 {
      font-size: 30px;
    }
  }
  .form-how-much {
    background: #ffffff;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
    border-radius: 21px;
    h4 {
      font-size: 18px;
    }
  }
  #tipe-in {
    font-size: 18px;
    color: #707070;
  }
  .type-border {
    color: #707070;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #000000;
  }
  .btn-calc {
    background-color: #202020;
    border-radius: 10px;
    color: #ffffff;
  }
  .calc-value {
    h5 {
      font-size: 18px;
    }
  }
  .result-t {
    color: #e00800;
  }
  .result-k {
    color: #00ab64;
  }
  .exclusive-text {
    h2 {
      font-size: 30px;
    }
    h4 {
      font-size: 18px;
    }
  }
  .back-screen-small {
    background-image: url(${kanarin});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 50px 0px 0px;
  }
  .contact {
    font-size: 30px;
    color: #ffffff;
  }
  .form-text {
    color: #ffffff;
  }
  .type-border-contact {
    font-size: 16px;
    background: #f2f2f2;
    border: 1px solid #dadada;
    border-radius: 8px;
    padding: 0px 25px 0px 25px;

    input {
      color: #626262;

      ::placeholder {
        color: #8692a6;
      }
    }
  }
  .drop {
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    padding: 5px 10px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 46px;
    appearance: auto;
  }
  .btn-know {
    background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    border-radius: 10px;
    color: #202020;
  }
  @media screen and (min-width: 768px) {
    .main-text {
      font-size: 50px;
    }
    .beyond {
      font-size: 22px;
    }
    .rect {
      width: 60px;
      height: 15px;
    }
    .content-text {
      font-size: 40px;
    }
    .ball {
      width: 30px;
      height: 30px;
    }
    .sportTech {
      font-size: 22px;
    }
    .sign-text {
      font-size: 35px;
    }
    .sign-text-p {
      font-size: 18px;
    }
    .vertical-bar {
      width: 5px;
    }
    .icons {
      width: 130px;
      height: 130px;
    }
    .shoe {
      width: 115px;
      height: 115px;
    }
    .how-work {
      font-size: 50px;
    }
    .mask {
      h2 {
        font-size: 40px;
      }
    }
    .form-how-much {
      h4 {
        font-size: 20px;
      }
    }
    .exclusive-text {
      h2 {
        font-size: 40px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .contact {
      font-size: 40px;
    }
  }
  @media screen and (min-width: 992px) {
    .main-text {
      font-size: 70px;
    }
    .beyond {
      font-size: 24px;
    }

    .banner-boots {
      top: 252px;
      left: -50px;
    }
    .rect {
      width: 85px;
      height: 19px;
    }
    .content-text {
      font-size: 50px;
    }
    .ball {
      width: 48px;
      height: 48px;
    }
    .sportTech {
      font-size: 24px;
    }
    .our-brand {
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 18px;
      }
    }
    .our-love {
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 18px;
      }
    }

    .sign-text {
      font-size: 40px;
    }
    .sign-text-p {
      font-size: 25px;
    }
    .vertical-bar {
      width: 5px;
    }

    .n-month {
      font-size: 80px;
    }
    .month {
      font-size: 30px;
    }

    .feature {
      h2 {
        font-size: 50px;
      }
    }
    .icons {
      width: 158px;
      height: 158px;
    }
    .shoe {
      width: 140px;
      height: 140px;
    }
    .how-work {
      font-size: 65px;
    }
    .line {
      position: absolute;
    }
    .line-hz {
      width: 79px;
    }

    .mask {
      background-image: url(${mask_ball});

      h2 {
        font-size: 55px;
      }
    }
    .form-how-much {
      h4 {
        font-size: 24px;
      }
    }

    .calc-value {
      h5 {
        font-size: 18px;
      }
    }
    .exclusive-text {
      h2 {
        font-size: 60px;
      }
      h4 {
        font-size: 24px;
      }
    }
    .back-screen-small {
      background: none;
    }
    .contact {
      font-size: 60px;
      color: #202020;
    }
    .form-text {
      color: #202020;
    }
  }
`;
