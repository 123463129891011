import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

import { Container, Menu, Link, AnchorLink } from './styles';

import logo from '~/assets/logos/logo.svg';
import logo_short from '~/assets/logos/logo-short.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [pageId, setPageId] = useState('home');

  useEffect(() => {
    setActive(false);
    if (location.hash) {
      setPageId(location.hash.replace('#', ''));
      history.push(process.env.PUBLIC_URL);
    }
  }, [history, location.hash]);

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(pageId, {
        smooth: 'easeInOutQuart',
        offset: -100,
      });
    }, 100);
  }, [pageId]);

  const LinkComponent = useMemo<any>(() => {
    if (location.pathname.includes('finalizar-assinatura')) {
      return Link;
    }

    return AnchorLink;
  }, [location.pathname]);

  return (
    <>
      <Container className="menu-header">
        <div className="container-fluid py-3 py-lg-0 w-lg-100 menu-fix d-lg-flex align-items-center">
          <div className="row w-lg-100">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center text-center">
                    <div className="d-none d-lg-block">
                      <NavLink to="/">
                        <img src={logo} alt="logo" />
                      </NavLink>
                    </div>
                    <div className="d-block d-lg-none">
                      <NavLink to="/">
                        <img src={logo_short} alt="logo" />
                      </NavLink>
                    </div>
                    <Menu active={active}>
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                      >
                        <span />
                        <span />
                        <span />
                      </button>
                      <div className="overflow overflow-auto menu-group active">
                        <div className="menu d-lg-flex">
                          <LinkComponent
                            href="#home"
                            to={`${process.env.PUBLIC_URL}/#home`}
                            offset={100}
                            className="btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                          >
                            Home
                          </LinkComponent>
                          <LinkComponent
                            href="#aboutUs"
                            to={`${process.env.PUBLIC_URL}/#aboutUs`}
                            offset={100}
                            className="btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                          >
                            Sobre nós
                          </LinkComponent>
                          <LinkComponent
                            href="#contact"
                            to={`${process.env.PUBLIC_URL}/#contact`}
                            offset={100}
                            className="btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                          >
                            Contato
                          </LinkComponent>
                          <div className="d-flex align-items-center text-center btn-menu">
                            <a
                              href={process.env.REACT_APP_APP_URL}
                              target="blank"
                              className="btn-login ms-lg-4 h5 d-flex justify-content-center align-items-center"
                            >
                              Logar
                            </a>
                          </div>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
