import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { ReactCreditCard } from '@repay/react-credit-card';
import '@repay/react-credit-card/dist/react-credit-card.css';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import { formatPrice } from '~/utils/format';
import Loading from '~/components/Loading';

interface IParams {
  token: string;
}

interface IFormData {
  number: string;
  name: string;
  expiry_date: string;
  cvc: string;
}

interface IPlan {
  name: string;
  description: string;
  pairs: string;
  price: string;
  time: string;
}

const FinalizeSubscription: React.FC = () => {
  const params = useParams<IParams>();
  const divRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormHandles>(null);
  const [values, setValues] = useState({
    name: '',
    number: '',
    expiration: '',
    cvc: '',
    flag: '',
  });
  const [focused, setFocus] = useState<
    'number' | 'cvc' | 'expiration' | 'name' | undefined
  >(undefined);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [plan, setPlan] = useState({} as IPlan);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`orders/tokens/${params.token}`)
      .then((response) => {
        setSubscriptionId(response.data.user.subscription.id);
        setPlan(response.data.user.subscription.plan);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.token]);

  const handleFocus = useCallback(
    (event) => {
      setFocus(event.target.name);
    },
    [setFocus]
  );

  const handleBlur = useCallback(() => setFocus(undefined), [setFocus]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      const noSpaceValue = value.replace(/ /g, '');
      setValues((v) => ({
        ...v,
        [name]: name === 'number' ? noSpaceValue : value,
      }));
      if (name === 'number') {
        const ReactCreditCardElement =
          document.getElementsByClassName('ReactCreditCard')[0];
        let flag = ReactCreditCardElement.classList[1].replace(
          'ReactCreditCard--',
          ''
        );

        flag = flag.charAt(0).toUpperCase() + flag.slice(1);
        setValues((v) => ({ ...v, flag: flag === 'Unknown' ? '' : flag }));
      }
    },
    [setValues]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      formRef.current?.setErrors({});
      try {
        setLoading(true);
        const schema = Yup.object().shape({
          number: Yup.string().required('O número é obrigatório'),
          name: Yup.string().required('O nome é obrigatório'),
          expiry_date: Yup.string().required(
            'A data de vencimento é obrigatória'
          ),
          cvc: Yup.string().required('O CVC é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dateParts = data.expiry_date.split('/');

        const formData = {
          subscription_id: subscriptionId,
          price: plan.price,
          number: data.number.replaceAll(' ', ''),
          holder: data.name,
          expiry_date: `${dateParts[0]}/20${dateParts[1]}`,
          cvv: data.cvc,
          brand: values.flag,
        };

        await api.post('orders/subscriptions', formData);
        formRef.current?.reset();

        setLoading(false);

        Swal.fire(
          'Pagamento realizado!',
          'Em breve você ira receber um e-mail com seus dados de login.',
          'success'
        ).then(() => {
          window.open(process.env.REACT_APP_APP_URL, '_self');
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Ocorreu um erro, tente novamente.', 'error');
        }
      }
    },
    [plan.price, subscriptionId, values.flag]
  );

  return (
    <Container className="py-5">
      {Object.keys(plan).length > 0 && (
        <div className="container">
          <div ref={divRef} className="row justify-content-between">
            <div className="col-lg-5">
              <h1 className="h2">Finalizar assinatura</h1>
              <div className="d-flex justify-content-center card-box py-4">
                <ReactCreditCard
                  {...values}
                  focused={focused}
                  placeholderName="Nome no cartão"
                />
              </div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <label className="d-block w-100 py-2">
                  <span className="d-block mb-2">Número do cartão</span>
                  <InputMask
                    kind="credit-card"
                    name="number"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={values.number}
                    className="input"
                  />
                </label>
                <label className="d-block w-100 py-2">
                  <span className="d-block mb-2">Nome</span>
                  <Input
                    name="name"
                    onChange={(e) =>
                      setValues({ ...values, name: e.target.value })
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={values.name}
                    className="input"
                  />
                </label>
                <div className="row">
                  <div className="col-6">
                    <label className="d-block w-100 py-2">
                      <span className="d-block mb-2">Data de vencimento</span>
                      <InputMask
                        kind="datetime"
                        options={{
                          format: 'MM/YY',
                        }}
                        name="expiry_date"
                        className="input"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={values.expiration}
                      />
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="d-block w-100 py-2">
                      <span className="d-block mb-2">CVC</span>
                      <InputMask
                        kind="custom"
                        options={{
                          mask: '999',
                        }}
                        name="cvc"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={values.cvc}
                        className="input"
                      />
                    </label>
                  </div>
                </div>
                <div className="w-100">
                  <button
                    type="submit"
                    className="btn w-100 h5 py-3 fw-semibold mt-3"
                  >
                    Pagar
                  </button>
                </div>
              </Form>
            </div>
            <div className="col-lg-5">
              <div className="box px-5 py-4">
                <h2 className="h3 mb-4">Resumo</h2>
                <div>
                  <p className="h5 fw-bold mb-4">Plano: {plan.name}</p>
                  <p>{plan.description}</p>
                </div>
                <hr className="my-4" />
                <div>
                  <ul className="px-0 mb0">
                    <li className="d-flex justify-content-between">
                      <p>Quantidade de pares:</p>
                      <p>{plan.pairs.toString().padStart(2, '0')}</p>
                    </li>
                    <li className="d-flex justify-content-between">
                      <p>Tempo do plano</p>
                      <p>{plan.time.toString().padStart(2, '0')} meses</p>
                    </li>
                    <li className="d-flex justify-content-between">
                      <p className="mb-0">Valor</p>
                      <p className="mb-0">
                        R${formatPrice(parseFloat(plan.price))} por mês
                      </p>
                    </li>
                  </ul>
                </div>
                <hr className="my-4" />
                <div className="d-flex justify-content-between">
                  <p className="h5 fw-bold">Total:</p>
                  <p className="h5 fw-bold">
                    R$ {formatPrice(parseFloat(plan.price))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loading active={loading} />
    </Container>
  );
};

export default FinalizeSubscription;
