import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { BsCalendarWeek } from 'react-icons/bs';
import { RiUser3Line } from 'react-icons/ri';
import { MdPayments, MdDeliveryDining } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container } from './styles';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import Models from '~/components/Models';
import InputMask from '~/components/InputMask';
import InputCheckbox from '~/components/InputCheckbox';

import pink_boots from '~/assets/images/pink-boots.png';
import soccer_ball from '~/assets/images/soccer-ball.svg';
import soccer_shoe from '~/assets/images/soccer-shoe.svg';
import play_soccer from '~/assets/images/play-soccer.png';
import knarin from '~/assets/banners/knarin.png';

interface IPlanResponse {
  id: string;
  name: string;
  description: string;
  price: number;
  time: number;
  pairs: number;
  recommended: boolean;
}

interface IPlan {
  id: string;
  name: string;
  description: string;
  price: string;
  totalPrice: string;
  time: string;
  pairs: string;
  recommended: boolean;
}

interface IFormData {
  name: string;
  email: string;
  age: string;
  celphone: string;
  brand: string;
  size: string;
  robot: boolean;
}

const Home: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const contactFormRef = useRef<FormHandles>(null);
  const [resultFooters, setResultFooters] = useState(0);
  const [resultKnarin, setResultKnarin] = useState(0);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [accept, setAccept] = useState(false);
  const [acceptError, setAcceptError] = useState('');
  const [celphoneError, setCelphoneError] = useState('');
  const [sizeSelected, setSizeSelected] = useState({} as IOption);

  useEffect(() => {
    api.get<IPlanResponse[]>('plans').then((response) => {
      const data = response.data.map<IPlan>((plan) => {
        const timeChange = plan.time / plan.pairs;
        const pairs = plan.pairs.toString().padStart(2, '0');
        return {
          ...plan,
          price: formatPrice(plan.price),
          totalPrice: formatPrice(
            plan.time * plan.price - plan.time * plan.price * 0.16666666666666
          ),
          pairs: `${pairs} ${
            plan.pairs > 1 ? 'Pares de chuteiras' : 'Par de chuteira'
          }, com troca a cada ${timeChange} meses.`,
          time: plan.time.toString().padStart(2, '0'),
        };
      });

      setPlans(data);
    });
  }, []);

  const sizeOptions = useMemo<IOption[]>(() => {
    const data = [];

    for (let i = 25; i <= 50; i += 1) {
      const size = {
        id: i,
        value: `${i}`,
        selected: sizeSelected.id === i,
        notSelectable: false,
      };
      data.push(size);
    }

    data.unshift({
      id: undefined as string | undefined,
      value: 'selecionar',
      selected: Object.keys(sizeSelected).length === 0,
      notSelectable: true,
    });

    return data;
  }, [sizeSelected]);

  const handleSubmitCalc = useCallback(() => {
    const formData = formRef.current?.getData();
    if (formData) {
      const totalFooters = formData.qtd_renew * formData.qtd_buy;
      const resultAuxFooters = totalFooters * 1200;
      const resultAuxKnarin = totalFooters * 75;
      setResultFooters(resultAuxFooters);
      setResultKnarin(resultAuxKnarin);
    }
  }, []);

  const handleSelectedSize = useCallback((option) => {
    setSizeSelected(option);
  }, []);

  const handleChangeAccept = useCallback((values) => {
    setAccept(values[0].selected);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      contactFormRef.current?.setErrors({});
      setAcceptError('');
      setCelphoneError('');
      try {
        if (!data.robot) {
          const schema = Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string()
              .email('Digite um e-mail válido')
              .required('O e-mail é obrigatório'),
            age: Yup.string().required('A idade é obrigatória'),
            celphone: Yup.string().required('O whatsapp é obrigatório'),
            brand: Yup.string().required('A marca é obrigatória'),
            size: Yup.string().required('O tamanho é obrigatório'),
            accept: Yup.string().when('$accept', {
              is: (acceptCheck: boolean) => acceptCheck,
              then: Yup.string().required('O aceite é obrigatório'),
              otherwise: Yup.string(),
            }),
            celphoneData: Yup.string().when('$celphoneData', {
              is: (celphoneDataCheck: boolean) => celphoneDataCheck,
              then: Yup.string(),
              otherwise: Yup.string().required('Informe um número válido'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
            context: {
              accept: !accept,
              celphoneData:
                data.celphone &&
                (data.celphone.length === 14 || data.celphone.length === 15) &&
                !data.celphone.includes('(55)'),
            },
          });

          const formData = {
            name: data.name,
            email: data.email,
            age: data.age,
            celphone: data.celphone,
            brand: data.brand,
            size: data.size,
            accept: true,
          };

          await api.post('leads', formData);
          setAccept(false);
          contactFormRef.current?.reset();
        }

        history.push(`${process.env.PUBLIC_URL}/solicitacao-recebida`);
        Swal.fire('Tudo certo!', 'Contato salvo com sucesso.', 'success').then(
          () => {
            history.push(`${process.env.PUBLIC_URL}`);
          }
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.accept) {
            setAcceptError(errors.accept);
          }
          if (errors.celphoneData && !errors.celphone) {
            setCelphoneError(errors.celphoneData);
          }
          contactFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Ocorreu um erro, tente novamente.', 'error');
        }
      }
    },
    [accept, history]
  );

  return (
    <Container id="home" className="mt-n5 mt-md-3 mt-lg-n4">
      <div className="container mb-5 pb-5">
        <div className="row justify-content-between align-items-center home-height btn-menu mb-5">
          <div className="col-lg-6 order-1 order-lg-0 mt-md-5 mt-lg-0 pt-md-5 pt-lg-0">
            <div className="h-100 d-flex align-items-center justify-content-center mt-md-5 mt-lg-0">
              <div>
                <h1 className="main-text fw-normal text-center text-lg-start">
                  Economize <br className="d-none d-md-block" /> em{' '}
                  <span className="text-gradient fw-medium">
                    {' '}
                    Chuteiras <br className="d-none d-md-block d-lg-none" />
                    Profissionais
                  </span>
                </h1>
                <p className="beyond text-center text-lg-start justify-content-center h5 me-lg-5 px-5 ps-lg-0 mt-5 pe-lg-5 text-secondary">
                  Assine sua chuteira e junte-se{' '}
                  <br className="d-none d-md-block d-lg-none" />
                  aos jogadores <br className="d-none d-lg-block" />
                  que estão indo
                  <br className="d-none d-md-block d-lg-none" />
                  para outro patamar.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 h-lg-100 background-boots mb-n5 pt-md-5 pt-lg-0 mt-md-5 mt-lg-0 mb-lg-0 order-0 order-lg-1">
            <img
              src={pink_boots}
              alt="pink_boots"
              className="banner-boots w-md-50 w-75 mx-auto d-block mx-lg-0 w-lg-100"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 rect d-flex mx-auto ms-lg-4 mt-md-5 mt-lg-0 justify-content-center" />
          <div
            id="aboutUs"
            className="col-12 mx-auto mx-lg-3 mt-3 d-flex justify-content-center justify-content-lg-start text-center text-lg-start"
          >
            <div className="col-12">
              <h2 className="fw-normal content-text">
                Somos a Knarin!
                <img
                  src={soccer_ball}
                  alt="soccer_ball "
                  className="ms-3 ms-lg-5 ball"
                />
              </h2>
              <div className="row my-4 justify-content-md-center justify-content-lg-start">
                <p className="col-12 col-md-8 col-lg-6 sportTech text-secondary">
                  Uma SportTech que fornece de forma exclusiva chuteiras de
                  elite através de uma forma{' '}
                  <b className=" sport-bold">única</b> e{' '}
                  <b className=" sport-bold">exclusiva</b> no Brasil.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6 pb-4 pb-lg-0">
            <div className="our-brand py-3 py-lg-5 px-lg-5 h-100">
              <h2 className="mt-4 px-5">Nosso</h2>
              <h2 className="fw-semibold px-5">Objetivo</h2>
              <p className="mt-4 mb-5 px-5">
                É facilitar o acesso a esses equipamentos de ponta
                principalmente por atletas que não possuem um rendimento
                financeiro compatível com a realidade de poucos atletas.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 pb-4 pb-lg-0">
            <div className="our-love py-3 py-lg-5 px-lg-5 h-100">
              <h2 className="mt-4 px-5">Nossa</h2>
              <h2 className="fw-semibold px-5">Paixão por futebol</h2>
              <p className="mt-4 mb-5 px-5">
                Nós somos apaixonados por esporte e fissuramos em ajudar quem
                mais precisa e nós viemos pra mudar tudo isso!!! <br /> &nbsp;
                <br className="d-none d-md-block d-lg-none" /> &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="signature py-5 mt-md-n5 mt-lg-0 ps-lg-3">
        <div className="container position-relative">
          <div className="row mt-5 ">
            <div className="col-12 sign-text">
              <h2>
                Opções de{' '}
                <b>
                  {' '}
                  <br className="d-block d-lg-none" /> Assinatura
                </b>
              </h2>
            </div>
          </div>
          <div className="row mt-4 mt-lg-5 pb-5 justify-content-between">
            <div className="col-12 col-md-6 sign-text-p ps-3 ps-md-0 pe-4 pe-md-2 pe-lg-4 d-flex">
              <div className="vertical-bar h-lg-100 me-lg-3" />
              <p className="mb-0 ps-2 ps-lg-0 pe-lg-3 text-p">
                Entregando uma forma inovadora e única no Brasil, você pode ter
                em primeira mão com exclusividade as mesmas chuteiras que
                atletas patrocinados estão utilizando na atualidade.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 sign-text-p ps-lg-5 pt-4 pt-md-0 pe-4 pe-lg-5">
              <p className="mb-0 ps-3 pe-lg-5">
                É uma opção importante para aquele atleta que quer sempre estar
                nos holofotes e sendo visto como diferenciado.
              </p>
            </div>
          </div>
          <div className="row mt-5 mb-5 pb-5">
            <div className="col-12">
              <div className="row">
                {plans.map((plan) => (
                  <div
                    key={plan.id}
                    className="col-10 col-md-4 mx-auto mx-lg-0 mb-5"
                  >
                    <div className="plan px-5 px-md-3 px-lg-5 py-3 py-md-0 py-lg-3">
                      <div className="d-flex my-4 justify-content-end">
                        <span className="ball-ace px-3 py-1">{plan.name}</span>
                      </div>
                      <div>
                        <span className="n-month">{plan.time}</span>
                      </div>
                      <div className="mb-lg-4">
                        <span className="month">Meses</span>
                      </div>
                      <div className="mb-lg-3">
                        <p className="attribute">{plan.description}</p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <a
                          href={`https://api.whatsapp.com/send/?phone=5516996170181&text=Olá, encontrei no site o plano ${plan.name} de ${plan.time} meses e fiquei interessado. Poderia me orientar sobre os próximos passos para adquiri-lo?`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="know-more py-3 mb-3 w-100 fw-medium mt-3 d-flex align-items-center justify-content-center"
                        >
                          Saiba mais
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature">
        <div className="container pt-5 pb-4">
          <div className="row mt-5">
            <div className="col-12">
              <h2>
                Como <br className="d-block d-lg-none" /> <b>funciona</b>
              </h2>
            </div>{' '}
          </div>
          <div className="row my-5 pb-5">
            <div className="col-10 line d-none d-xl-block position-relative" />
            <div className="work position-relative d-flex mb-lg-5 pb-5 flex-wrap flex-xl-nowrap justify-content-center justify-content-md-between">
              <div className="col-8 col-md-6 col-xl d-xl-flex justify-content-center flex-wrap">
                <div className="icons mx-auto mt-5 d-flex justify-content-center align-items-center">
                  <img
                    src={soccer_shoe}
                    alt="soccer_shoe"
                    className="shoe mx-auto d-block p-3 justify-content-center"
                  />
                </div>
                <p className="feature-text text-center mt-4 px-md-5 fw-normal">
                  Você escolhe o modelo.
                </p>
              </div>
              <div className="col-8 col-md-6 col-xl d-xl-flex justify-content-center flex-wrap">
                <div className="icons mx-auto mt-5 d-flex align-items-center">
                  <BsCalendarWeek className="how-work mx-auto d-flex d-block " />
                </div>
                <p className="feature-text text-center mt-4 px-md-5 fw-normal">
                  Escolhe o melhor período que faz sentido para você.
                </p>
              </div>
              <div className="col-8 col-md-6 col-xl d-xl-flex justify-content-center flex-wrap">
                <div className="icons mx-auto mt-5 d-flex align-items-center">
                  <RiUser3Line className="how-work mx-auto d-block " />
                </div>
                <p className="feature-text text-center mt-4 px-md-5 fw-normal">
                  Envia os dados e informações para assinatura
                </p>
              </div>
              <div className="col-8 col-md-6 col-xl d-xl-flex justify-content-center flex-wrap">
                <div className="icons mx-auto mt-5 d-flex align-items-center">
                  <MdPayments className="how-work mx-auto d-block" />
                </div>
                <p className="feature-text text-center mt-4 px-md-5 fw-normal">
                  Realiza o pagamento
                </p>
              </div>
              <div className="col-8 col-md-6 col-xl d-xl-flex justify-content-center flex-wrap">
                <div className="icons mx-auto mt-5 d-flex align-items-center">
                  <MdDeliveryDining className="how-work mx-auto d-block " />
                </div>
                <p className="feature-text text-center mt-4 px-lg-5 fw-normal">
                  Aguarda receber na sua casa
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5 py-5">
        <div className="row pt-lg-5">
          <div className="col-12 mt-lg-5 mb-3">
            <div className="line-hz" />
          </div>
          <div className="col-12 mt-3 mb-5">
            <h2 className="content-text">Alguns modelos</h2>
          </div>
          <div className="col-12">
            <Models />
          </div>
        </div>
      </div>
      <div className="mask">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-12">
              <h2 className="price">
                Você sabe quanto <br className="d-none d-lg-block" />
                <b>
                  gasta <br className="d-lg-none d-block" />
                  com chuteiras
                </b>{' '}
                <br className="d-none d-lg-block" />
                no ano?
              </h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-lg-7 form-how-much pt-5 pt-lg-3">
              <Form
                ref={formRef}
                onSubmit={handleSubmitCalc}
                className="row m-3 m-lg-5"
              >
                <div className="col-12 col-lg-6 pe-lg-4 d-flex flex-column justify-content-between">
                  <h4 className="pb-lg-3">
                    Quantas vezes por ano você renova as suas chuteiras?
                  </h4>
                  <Input
                    name="qtd_renew"
                    type="number"
                    placeholder="Digite aqui"
                    className="type-border"
                  />
                </div>
                <div className="col-12 col-lg-6 d-flex flex-column justify-content-between">
                  {' '}
                  <h4 className="pt-5 pt-lg-0 pb-lg-3">
                    Quantas chuteiras você compra por renovação?{' '}
                    <br className="d-none d-lg-block" /> &nbsp;
                  </h4>
                  <Input
                    name="qtd_buy"
                    type="number"
                    placeholder="Digite aqui"
                    className="type-border"
                  />
                </div>
                <div className="col-12 text-center text-lg-end py-4 mb-4">
                  <button
                    type="submit"
                    className="btn btn-calc fw-semibold mt-3 px-5"
                  >
                    Calcular
                  </button>
                </div>
                {resultFooters > 0 || resultKnarin > 0 ? (
                  <>
                    <div className="col-12 col-lg-6 pe-3 calc-value">
                      <h5 className="pb-3 text-center">
                        Quanto você está gastando hoje:
                      </h5>
                      <h3 className="pb-3 fw-semibold result-t d-flex justify-content-center">
                        R$ {resultFooters} /Ano
                      </h3>
                    </div>
                    <div className="col-12 col-lg-6 calc-value">
                      <h5 className="pb-3 text-center">
                        Valor investido com a Knarin:
                      </h5>
                      <h3 className="pb-3 fw-semibold result-k d-flex justify-content-center">
                        R$ {resultKnarin} /Mês
                      </h3>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="exclusive">
        <div className="container">
          <div className="row my-5 py-5 align-items-center">
            <div className="col-12 col-lg-6 order-1 order-lg-0">
              <div className="line-hz mt-5 mb-3" />
              <div className="row">
                <div className="col-11 col-lg-8 exclusive-text">
                  <h2 className="pb-4">Exclusividade</h2>
                  <h4 className="text-secondary pe-2 pe-lg-4">
                    Vários atletas de clubes de Série A do estadual já está
                    assinado com a gente e vocês terão a oportunidade de se
                    juntar a essa comunidade que vai mudar de vez o cenário do
                    consumo de chuteiras no Brasil.
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 image-soccer order-0 order-lg-1">
              <img src={play_soccer} alt="play-soccer" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="d-lg-flex">
        <img src={knarin} alt="knarin" className="d-none d-lg-block" />
        <div className="container">
          <div className="row">
            <div id="contact" className="col-12 back-screen-small">
              <h2 className="mt-5 mb-5 mx-xl-5 mb-lg-0 contact">
                Entre <br />
                <b className="fw-semibold">em contato</b>
              </h2>
              <Form
                ref={contactFormRef}
                onSubmit={handleSubmit}
                className="row mx-xl-5 my-lg-5 justify-content-end form-text"
              >
                <div className="d-flex flex-column justify-content-between col-12 col-lg-6 pb-4 pe-lg-4">
                  <h6 className="pb-2 fw-medium">Nome e Sobrenome</h6>
                  <Input
                    name="name"
                    placeholder="ex.: João Silva"
                    className="type-border-contact"
                  />
                </div>
                <div className="col-12 col-lg-6 pb-4 pe-lg-4">
                  <h6 className="pb-2 fw-medium">E-mail</h6>
                  <Input
                    type="email"
                    name="email"
                    placeholder="exemplo@exemplo.com"
                    className="type-border-contact"
                  />
                </div>
                <div className="col-12 col-lg-6 pb-4 pe-lg-4">
                  <h6 className="pb-2 fw-medium">Idade</h6>
                  <Input
                    type="number"
                    name="age"
                    placeholder="xx"
                    className="type-border-contact"
                  />
                </div>
                <div className="col-12 col-lg-6 pb-4 pe-lg-4">
                  <h6 className="pb-2 fw-medium">Whatsapp</h6>
                  <InputMask
                    kind="cel-phone"
                    name="celphone"
                    placeholder="(xx) xxxxx-xxxx"
                    className="type-border-contact"
                  />
                  {celphoneError && (
                    <span className="small text-danger error">
                      {celphoneError}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between col-6 pe-lg-4 pt-lg-4">
                  <h6 className="pb-2 fw-medium">Marca favorita</h6>
                  <Input
                    name="brand"
                    placeholder="Digite aqui"
                    className="type-border-contact"
                  />
                </div>
                <div className="d-flex flex-column justify-content-between col-6 pe-lg-4 pt-lg-4">
                  <h6 className="pb-2 fw-medium">Quanto você calça?</h6>
                  <Select
                    name="size"
                    className="type-border-contact"
                    options={sizeOptions}
                    onChange={handleSelectedSize}
                  />
                </div>
                <div className="col-12 col-lg-6 pb-5 py-lg-4 mb-4 pe-lg-4">
                  <Input name="robot" className="d-none" />
                  <button
                    type="submit"
                    className="btn btn-know w-100 h4 py-3 fw-semibold mt-3"
                  >
                    Enviar
                  </button>
                  <InputCheckbox
                    name="accept"
                    options={[
                      {
                        label:
                          'Aceito compartilhar meus dados afim de receber contato da empresa.',
                        value: 1,
                      },
                    ]}
                    className="input-checkbox mt-3 justify-content-start"
                    error={acceptError}
                    onChange={handleChangeAccept}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
