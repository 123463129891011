import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '~/pages/Home';
import FinalizeSubscription from '~/pages/FinalizeSubscription';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/finalizar-assinatura/:token`}
        exact
        component={FinalizeSubscription}
      />
    </Switch>
  );
};

export default routes;
