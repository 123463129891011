import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Link as LinkComponent } from 'react-router-dom';
import AnchorLinkComponent from 'react-anchor-link-smooth-scroll';

interface IMenuProps {
  active: boolean;
}

interface ILinkProps {
  href: string;
  to: string;
  offset: number;
}

export const Container = styled.header`
  top: 0;
  background: transparent;
  z-index: 100;
  width: 100%;
  height: 90px;

  .menu-fix {
    height: 90px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  @media screen and (max-width: 991px) {
    height: 64px;
    margin-bottom: -4.7rem;
    .menu-fix {
      height: 64px;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  .btn-idiom {
    color: #000 !important;
    font-size: 16px !important;
    img {
      width: 22px;
      height: 22px;
      transition-duration: 0.3s;
      filter: grayscale(1);
      border-radius: 50%;
      opacity: 70%;
    }

    :hover img,
    .active-idiom {
      filter: grayscale(0);
    }
    :hover,
    :focus {
      color: #606060 !important;
    }
  }
  .min-height {
    min-height: 50px;
  }
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #131313;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        width: 50%;
        margin: 6px auto 6px 0px;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  .menu,
  .logoff {
    .btn-menu {
      transition: all 0.3s ease;
      background: linear-gradient(90deg, #fff, #fff);
      right: 20px;
      bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 400;
      color: #202020;
    }
    .btn-login {
      background: linear-gradient(90deg, #41b69f -44.4%, #aaf23c 130.97%);
      font-weight: 600;
      width: 135px;
      height: 45px;
      border-radius: 10px;
      :hover {
        color: #202020;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .overflow {
      ::-webkit-scrollbar {
        background-color: #fff !important;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #fff !important;
      }
    }
    .btn-menu {
      a {
        background-color: #000;
        color: #fff;
        border-radius: 15px;
        padding: 17.5px 0px;
        width: 212px;

        :hover {
          background-color: ${lighten(0.1, '#000000')};
          color: ${lighten(0.1, '#fff')};
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
    .menu-item {
      font-size: 32px;
    }
    .menu-group {
      width: 100%;
      height: 100vh;
      background: rgba(255, 255, 255, 1);
      transition-duration: 0.4s;
      top: 47px;
      left: ${(props) => (props.active ? '0' : '1000%')};
    }

    .menu {
      padding-top: 4vh;
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px;
        }

        small {
          position: relative;
          z-index: 0;
          text-align: left;
          opacity: 1;
          width: 60px;
          justify-content: start;
          left: 0 !important;
          width: 100px !important;
          padding: 0 !important;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .menu-group {
      position: absolute;
    }
  }
`;

export const Link = styled(LinkComponent)<ILinkProps>``;

export const AnchorLink = styled(AnchorLinkComponent)<ILinkProps>``;
