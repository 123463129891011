import styled from 'styled-components';

interface IContainer {
  active: boolean;
}

export const Container = styled.div<IContainer>`
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #00000055;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      width: 50px;
      height: 50px;
    }

    span {
      color: #fff;
      display: block;
      margin-top: 20px;
      font-size: 20px;
    }
  }
`;
