import styled from 'styled-components';

export const Container = styled.header`
  .footer-border {
    background: #000000;
    h5 {
      font-size: 18px;
      color: #ffffff;
    }
  }
  .icon-footer {
    background: #1fc19a;
    border-radius: 28px;
    width: 35px;
    height: 35px;
    svg {
      font-size: 20px;
      color: #000000;
    }
  }
  .icon-whatsapp-call {
    background: #22de57;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    position: fixed;
    bottom: 100px;
    right: 20px;
  }
  .line-footer {
    border: 1px solid #464646;
  }
  @media screen and (min-width: 992px) {
    .icon-footer {
      width: 56px;
      height: 56px;
      svg {
        font-size: 28px;
      }
    }
    .icon-whatsapp-call {
      bottom: 10px;
      right: 100px;
    }
  }
`;
