import React from 'react';
import Slider from 'react-slick';
import soccer_shoe_pink from '~/assets/images/soccer-shoe-pink.png';
import soccer_shoe_green from '~/assets/images/soccer-shoe-green.png';
import soccer_shoe_gold from '~/assets/images/soccer-shoe-gold.png';
import soccer_shoe_yellow from '~/assets/images/soccer-shoe-yellow.png';
import soccer_shoe_white from '~/assets/images/soccer-shoe-white.png';
import { Container, ButtonLeft, ButtonRight } from './styles';
import { RiArrowDropLeftLine, RiArrowDropRightLine } from 'react-icons/ri';

interface IbuttonProps {
  onClick?(): void;
}

const PrevArrow: React.FC<IbuttonProps> = ({ onClick }) => {
  return (
    <ButtonLeft onClick={onClick}>
      <RiArrowDropLeftLine size={68} color="#303030" />
    </ButtonLeft>
  );
};
const NextArrow: React.FC<IbuttonProps> = ({ onClick }) => {
  return (
    <ButtonRight onClick={onClick}>
      <RiArrowDropRightLine size={68} color="#303030" />
    </ButtonRight>
  );
};

const Models: React.FC = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Container className="pb-5">
      <Slider {...settings} className="position-relative d-flex pb-5">
        <div className="pe-md-3">
          <div className="img-car px-3 mt-3">
            <img
              src={soccer_shoe_pink}
              alt="pink"
              className="pb-4 soccer-shoe-esp d-block mb-5 mx-auto"
            />
            <p>
              Chuteira de Campo <br />
              Adidas Artilheira V
            </p>
          </div>
        </div>
        <div className="pe-md-3">
          <div className="img-car px-3 mt-3">
            <img
              src={soccer_shoe_green}
              alt="pink"
              className="pb-4 soccer-shoe-esp d-block mb-5 mx-auto"
            />
            <p>
              Adidas X <br />
              Speedportal
            </p>
          </div>
        </div>
        <div className="pe-md-3">
          <div className="img-car px-3 mt-3">
            <img
              src={soccer_shoe_gold}
              alt="pink"
              className="pb-4 soccer-shoe-esp d-block mb-5 mx-auto"
            />
            <p>
              Futebol Future Z 4.4 <br />
              Fg/ag Verde EU 28
            </p>
          </div>
        </div>
        <div className="pe-md-3">
          <div className="img-car px-3 mt-3">
            <img
              src={soccer_shoe_yellow}
              alt="pink"
              className="pb-4 soccer-shoe-esp d-block mb-5 mx-auto"
            />
            <p>
              Mercurial Vapor 14 <br />
              Academy (Infantil)
            </p>
          </div>
        </div>
        <div className="pe-md-3">
          <div className="img-car px-3 mt-3">
            <img
              src={soccer_shoe_white}
              alt="pink"
              className="pb-4 soccer-shoe-esp d-block mb-5 mx-auto"
            />
            <p>
              Adidas Predator 22 4 <br />
              Unissex
            </p>
          </div>
        </div>
        <button
          type="button"
          className="img-car-knowMore border-none mt-3 py-5 d-flex justify-content-center"
        >
          <span className="h4 py-4 text-start fw-semibold">
            Saiba mais em:
            <br />
            Catálogo
          </span>
        </button>
      </Slider>
    </Container>
  );
};

export default Models;
